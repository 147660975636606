export const PLANT_FINANCIAL_ID = {
	SOLD_OUT_DROPDOWN: "dropdown-soldout",
	SOLD_OUT_ITEMS: "soldout-items",
	UOM_DROPDOWN: "dropdown-uom",
	UOM_ITEMS: "uom-items",
	CONTRIBUTION_MARGIN: "box-contributionMargin",
	VARIABLE_COST: "box-variableCost",
	UOM_VALUE: "text-uom",
	TOTAL_COST: "text-totalVariableCost",
	PFC_HEADER_TITLE: "header_PFC_title",
	PARAMETER_HEADER_LABEL: "parameter_table_label_header",
	VARIABLE_HEADER_LABEL: "variable_table_label_header",
	VARIABLE_HEADER_VALUE: "variable_table_value_header",
	TOOLTIP_CONTRIBUTIONMARGIN: "tooltip_contributionMargin",
	TOOLTIP_ISPLANTSOLDOUT: "tooltip_isPlantSoldout",
	TOOLTIP_SCRAPPERCENTAGE: "tooltip_scrapPercentage",
	TEXTFIELD_CONTRIBUTIONMARGIN: "contributionMargin",
	TEXTFIELD_AVERAGESCRAP: "averageScrap",
	TEXTFIELD_HOURLYASSOCIATES: "hourlyAssociates",
	TEXTFIELD_SALARIEDASSOCIATES: "salariedAssociates",
	TEXTFIELD_MATERIALCOST: "materialCost",
	TEXTFIELD_UTILITYCOST: "utilityCost",
	TEXTFIELD_MISCELLANEOUSCOST: "miscellaneousCost",
	ERROR: "error",
	TEXTFIELD: "textfield",
};

export const DECIMAL_REGEX = /^\d{0,50}\.?\d{0,50}$/;

export const SCRAP_PERCENTAGE_REGEX = /^\d*(?:\.\d{0,2})?$/;

export default {};
