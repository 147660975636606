import {
	Box,
	MenuItem,
	Typography,
	InputAdornment,
	styled,
	Fade,
	alpha,
} from "@mui/material";
import * as yup from "yup";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { globalstate$ } from "@smf/ui-util-app";
import { isEmpty } from "lodash";

import GeneralConfigBody from "../layouts/GeneralConfigBody";
import GeneralConfigFooter from "../layouts/GeneralConfigFooter";
import GeneralTable from "../../components/GeneralTable";
import GeneralDropdown from "../../components/GeneralDropdown";
import GeneralTextField from "../../components/GeneralTextField";
import MESSAGE_STRINGS from "../../constants/en-us";
import InfoIcon from "../../assets/img/greyInfo.svg";
import ErrorIcon from "../../assets/img/errorIcon16.svg";
import {
	DROPDOWN_CONSTANTS,
	GENERAL_CONFIGURATION_STEPS,
	QUERY_CONSTANTS,
	TOAST_REDUCER_CONSTANTS,
	PFC_VALUES,
	STEPPER_STATUSES,
	NAVIAGTION_TYPE,
	APP_URL,
} from "../../constants";
import {
	getFinancialsData,
	submitFinancialsData,
} from "../../utils/apiHelper.js";
import { useToastContext } from "../../context/toastContext";
import CommonBackdrop from "../../components/CommonBackdrop";
import {
	PLANT_FINANCIAL_ID,
	DECIMAL_REGEX,
	SCRAP_PERCENTAGE_REGEX,
} from "./PlantFinancialCalculationConstants";
import {
	updateStepStatusCompleted,
	updateStepStatusInProgress,
	useGeneralConfigContext,
} from "../../context/generalConfigContext";
import { parseToDecimal } from "../../utils/helpers";
import {
	pushModal,
	closeRecentModal,
	useGeneralModalDispatchContext,
} from "../../context/generalModalContext";
import { GeneralDialog } from "../../components/GeneralDialog";
import NotificationDialog from "../../components/GeneralDialog/NotificationDialog";
import useRxjsState from "../../hooks/useRxjsState";
import {
	updateFactoryGeneralEditAction,
	updateRxjsState,
} from "../../utils/rxjs";
import NavigationModal from "../layouts/NavigationModal/NavigationModal";
import LoadingIndicator from "../../components/LoadingIndicator";

const StyledTooltip = styled(({ className, ...props }) => (
	<Tooltip
		{...props}
		classes={{ popper: className }}
		TransitionComponent={Fade}
		TransitionProps={{ timeout: 600 }}
		arrow
		placement="right-end"
	/>
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: "15.125rem",
		fontSize: ".85rem",
		borderRadius: "0.25em",
		backgroundColor: theme.palette.background.eerieBlack,
		lineHeight: "1.5rem",
		fontWeight: 400,
		color: theme.palette.text.lightYellowishGray,
		padding: ".55rem",
		margin: "-0.5rem",
		left: ".7rem",
	},
	[`& .${tooltipClasses.arrow}`]: {
		fontSize: 20,
		color: theme.palette.background.eerieBlack,
	},
}));

const StyledRowBox = styled(Box)(() => ({
	display: "flex",
	justifyContent: "flex-end",
	alignItems: "center",
}));

const StyledTableBox = styled(Box)(({ theme }) => ({
	"& .basicGeneralTable_bodyCell": {
		borderBottom: `.0625rem solid ${alpha(
			theme.palette.background.darkGrey,
			0.3
		)}`,
		lineHeight: "1",
		paddingTop: ".6rem",
		paddingBottom: ".6rem",
	},
	marginTop: "1rem",
}));

export function ErrorComponent({ message, testid }) {
	return (
		<Box mt=".2rem" width="9rem" display="flex" alignItems="baseline">
			<Box position="relative" mr="1rem" height="1.25rem">
				<Box position="absolute" sx={{ top: ".45rem" }}>
					<ErrorIcon height="16" width="16" />
				</Box>
			</Box>
			<Box display="inline-block" width="7.5rem">
				<Typography
					variant="subtitle1"
					color="white"
					data-testid={`${PLANT_FINANCIAL_ID.ERROR}-${testid}`}
					sx={{
						verticalAlign: "super",
						margin: 1,
						lineHeight: "1rem",
						fontSize: "0.75rem",
					}}
				>
					{message}
				</Typography>
			</Box>
		</Box>
	);
}

ErrorComponent.propTypes = {
	message: PropTypes.string.isRequired,
	testid: PropTypes.string,
};

export function PFCTextfield({
	fieldName,
	formik,
	styles = {},
	generalConfigDispatch = () => null,
	testid,
	regexValidator = DECIMAL_REGEX,
	...props
}) {
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
		>
			<GeneralTextField
				autoComplete="off"
				data-testid={`${PLANT_FINANCIAL_ID.TEXTFIELD}-${testid}`}
				type="text"
				name={fieldName}
				textAlign="right"
				sx={{ width: "10rem", ...styles }}
				{...formik.getFieldProps(fieldName)}
				onChange={(e) => {
					const newValue = e.target.value;
					if (newValue.match(regexValidator)) {
						updateStepStatusInProgress(
							generalConfigDispatch,
							GENERAL_CONFIGURATION_STEPS.PLANT_FINANCIAL_CALCULATIONS
						);
						formik.getFieldProps(fieldName).onChange(e);
					}
				}}
				onBlur={(e) => {
					if (
						formik.values[fieldName] !== formik.initialValues[fieldName] ||
						formik.touched[fieldName]
					) {
						formik.handleBlur(e);
					}
				}}
				error={formik.errors[fieldName] && formik.touched[fieldName]}
				maxLength={8}
				{...props}
			/>
			{formik.errors[fieldName] && formik.touched[fieldName] && (
				<ErrorComponent message={formik.errors[fieldName]} testid={testid} />
			)}
		</Box>
	);
}

PFCTextfield.propTypes = {
	fieldName: PropTypes.string.isRequired,
	formik: PropTypes.shape({
		getFieldProps: PropTypes.func,
		handleBlur: PropTypes.func,
		errors: PropTypes.shape({}),
		initialValues: PropTypes.shape({}),
		values: PropTypes.shape({}),
		touched: PropTypes.shape({}),
	}),
	styles: PropTypes.shape({}),
	generalConfigDispatch: PropTypes.func,
	regexValidator: PropTypes.shape({}),
	testid: PropTypes.string,
};

function PlantFinancialCalculations({
	onPreviousClickAPI,
	onNextClickAPI,
	navigateTo,
	isInterDepedencyState,
}) {
	const [uom, setUom] = useState({
		UoM: "UoM",
		error: false,
		initialConfig: true,
		changed: false,
	});
	const [sold, setSold] = useState({ sold: "", error: false });
	const [variableCostData, setVariableCostData] = useState({});

	const { toastDispatch } = useToastContext();
	const { rxjsState } = useRxjsState();
	const {
		factoryGeneralEditState: { action: factoryGeneralAction = {} } = {},
	} = rxjsState;
	const {
		generalConfigState: { currentStep, isMaintenanceMode, steps },
		generalConfigDispatch,
	} = useGeneralConfigContext();
	const modalDispatch = useGeneralModalDispatchContext();
	const queryClient = useQueryClient();

	const entityId = globalstate$?.value?.plantId || "KANSAS_PLANT";
	const validationRegex = /^\d*[.]\d{2}$/;
	const {
		isLoading: isGetFinancialLoading,
		isFetching: isGetFinancialFetching,
		data: financialData,
	} = useQuery(
		[QUERY_CONSTANTS.FETCH_PLANT_FINC, entityId],
		() => getFinancialsData({ factoryID: entityId }),
		{
			onSuccess: (response) => {
				if (response.parameters.UoM) {
					const UoM = response.parameters.UoM.split("");
					UoM.pop();
					const lowercase = UoM.splice(1).join("").toLowerCase();
					UoM.push(lowercase);
					const value = UoM.join("");
					setUom({ ...uom, UoM: value, initialConfig: false });
				}
				if (response.parameters.soldOut !== null) {
					const soldOut = `${response.parameters.soldOut}`;
					setSold({ ...sold, sold: soldOut });
				}
				const variableDataObj = response.data.reduce((acc, curr) => {
					return { ...acc, [curr?.label]: curr?.value };
				}, {});
				setVariableCostData(variableDataObj);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { mutate: setPFCdata, isLoading: isSubmitLoading } = useMutation(
		QUERY_CONSTANTS.SUBMIT_FINANCIALS,
		submitFinancialsData,
		{
			onSuccess: () => {
				queryClient.invalidateQueries(QUERY_CONSTANTS.FETCH_PLANT_FINC);
				toastDispatch({
					type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
					payload: {
						message: MESSAGE_STRINGS["GPFC.toast.success"],
					},
				});

				updateRxjsState({ isFGCComplete: true });
				updateStepStatusCompleted(generalConfigDispatch, currentStep);
				if (!isMaintenanceMode) onNextClickAPI();
				else if (isMaintenanceMode && uom.changed) {
					updateRxjsState({ isEIPorUoMChanged: true });
					setUom({ ...uom, changed: false });
				}
			},
			onError: () => {
				toastDispatch({
					type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
					payload: {
						message: MESSAGE_STRINGS["Toast.message.ERROR"],
					},
				});
			},
		}
	);

	const validationSchema = yup.object({
		[MESSAGE_STRINGS["GPFC.heading.contributionMargin"]]: yup
			.string()
			.required(MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"])
			.test(
				"Zero Check",
				MESSAGE_STRINGS["GPFC.errors.zero"],
				(value) => Number(value) !== 0
			)
			.test(
				"Decimal upto two places",
				MESSAGE_STRINGS["GPFC.errors.validMoneyAmount"],
				(value) => validationRegex.test(value)
			),
		[MESSAGE_STRINGS["GPFC.heading.scrapPercentage"]]: yup
			.number()
			.required(MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"])
			.min(0, "Values should be between 0-100.")
			.max(100, "Values should be between 0-100."),
		[MESSAGE_STRINGS["GPFC.label.hourlyAssociates"]]: yup
			.string()
			.required(MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"])
			.test(
				"Zero Check",
				MESSAGE_STRINGS["GPFC.errors.zero"],
				(value) => Number(value) !== 0
			)
			.test(
				"Decimal upto two places",
				MESSAGE_STRINGS["GPFC.errors.validMoneyAmount"],
				(value) => validationRegex.test(value)
			),
		[MESSAGE_STRINGS["GPFC.label.salariedAssociates"]]: yup
			.string()
			.required(MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"])
			.test(
				"Zero Check",
				MESSAGE_STRINGS["GPFC.errors.zero"],
				(value) => Number(value) !== 0
			)
			.test(
				"Decimal upto two places",
				MESSAGE_STRINGS["GPFC.errors.validMoneyAmount"],
				(value) => validationRegex.test(value)
			),
		[MESSAGE_STRINGS["GPFC.label.materialCost"]]: yup
			.string()
			.required(MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"])
			.test(
				"Zero Check",
				MESSAGE_STRINGS["GPFC.errors.zero"],
				(value) => Number(value) !== 0
			)
			.test(
				"Decimal upto two places",
				MESSAGE_STRINGS["GPFC.errors.validMoneyAmount"],
				(value) => validationRegex.test(value)
			),
		[MESSAGE_STRINGS["GPFC.label.utilityCost"]]: yup
			.string()
			.required(MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"])
			.test(
				"Zero Check",
				MESSAGE_STRINGS["GPFC.errors.zero"],
				(value) => Number(value) !== 0
			)
			.test(
				"Decimal upto two places",
				MESSAGE_STRINGS["GPFC.errors.validMoneyAmount"],
				(value) => validationRegex.test(value)
			),
		[MESSAGE_STRINGS["GPFC.label.miscellaneousVariableCost"]]: yup
			.string()
			.required(MESSAGE_STRINGS["ERROR_MESSAGES.emptyField"])
			.test(
				"Zero Check",
				MESSAGE_STRINGS["GPFC.errors.zero"],
				(value) => Number(value) !== 0
			)
			.test(
				"Decimal upto two places",
				MESSAGE_STRINGS["GPFC.errors.validMoneyAmount"],
				(value) => validationRegex.test(value)
			),
	});

	const formik = useFormik({
		initialValues: {
			[MESSAGE_STRINGS["GPFC.heading.contributionMargin"]]:
				parseToDecimal(financialData?.parameters?.contributionMargin) ||
				undefined,
			[MESSAGE_STRINGS["GPFC.heading.scrapPercentage"]]:
				financialData?.parameters?.scrapPercentage || undefined,
			[MESSAGE_STRINGS["GPFC.label.hourlyAssociates"]]:
				parseToDecimal(
					variableCostData?.[MESSAGE_STRINGS["GPFC.label.hourlyAssociates"]]
				) || undefined,
			[MESSAGE_STRINGS["GPFC.label.salariedAssociates"]]:
				parseToDecimal(
					variableCostData?.[MESSAGE_STRINGS["GPFC.label.salariedAssociates"]]
				) || undefined,
			[MESSAGE_STRINGS["GPFC.label.materialCost"]]:
				parseToDecimal(
					variableCostData?.[MESSAGE_STRINGS["GPFC.label.materialCost"]]
				) || undefined,
			[MESSAGE_STRINGS["GPFC.label.utilityCost"]]:
				parseToDecimal(
					variableCostData?.[MESSAGE_STRINGS["GPFC.label.utilityCost"]]
				) || undefined,
			[MESSAGE_STRINGS["GPFC.label.miscellaneousVariableCost"]]:
				parseToDecimal(
					variableCostData?.[
						MESSAGE_STRINGS["GPFC.label.miscellaneousVariableCost"]
					]
				) || undefined,
		},
		enableReinitialize: true,
		validationSchema,
		validateOnMount: true,
	});
	function handleNavigation(location = "") {
		if (factoryGeneralAction?.type === NAVIAGTION_TYPE.STEP) {
			navigateTo(location, factoryGeneralAction?.type);
			return;
		}
		if (factoryGeneralAction.location) {
			navigateTo(location);
		}
	}
	const handleChangeUoM = React.useCallback(
		(e) => {
			const { initialConfig } = uom;
			function updateUoM(obj = {}) {
				updateStepStatusInProgress(
					generalConfigDispatch,
					GENERAL_CONFIGURATION_STEPS.PLANT_FINANCIAL_CALCULATIONS
				);
				setUom({ ...uom, ...obj });
			}
			if (initialConfig === true)
				updateUoM({ UoM: e.target.value, error: false });
			else if (
				`${e.target.value.toUpperCase()}S` === financialData.parameters.UoM &&
				initialConfig === false
			)
				updateUoM({ UoM: e.target.value, error: false });
			else {
				pushModal(
					modalDispatch,
					<GeneralDialog open fullWidth={false}>
						<NotificationDialog
							type="confirm"
							handleSave={() => {
								closeRecentModal(modalDispatch);
								updateUoM({ ...uom, UoM: e.target.value, changed: true });
							}}
							handleClose={() => {
								closeRecentModal(modalDispatch);
							}}
							cancelText={
								MESSAGE_STRINGS[
									"GENERAL_CONFIGURATIONS.NOTIFICATION.cancelText"
								]
							}
							confirmText={
								MESSAGE_STRINGS[
									"GENERAL_CONFIGURATIONS.NOTIFICATION.confirmText"
								]
							}
							customTitle={MESSAGE_STRINGS["GPFC.modal.title"]}
							customText={MESSAGE_STRINGS["GPFC.modal.label"]}
							noteText={MESSAGE_STRINGS["GPFC.modal.confirmText"]}
							lightText={false}
						/>
					</GeneralDialog>
				);
			}
		},
		[financialData, modalDispatch, uom, generalConfigDispatch]
	);

	const handleChangeSold = React.useCallback(
		(e) => {
			setSold({ ...sold, sold: e.target.value, error: false });
		},
		[sold]
	);

	const parameterColoumns = useMemo(() => {
		return [
			{
				Header: (
					<Typography
						data-testid={`${PLANT_FINANCIAL_ID.PARAMETER_HEADER_LABEL}`}
						variant="body2"
					>
						{MESSAGE_STRINGS["GPFC.heading.parameters"]}
					</Typography>
				),
				accessor: MESSAGE_STRINGS["GPFC.accessor.label"],
				width: "49%",
			},
			{
				Header: "",
				accessor: MESSAGE_STRINGS["GPFC.accessor.value"],
			},
		];
	}, []);

	function getTotalVariableCost() {
		const keys = Object.values(formik.values);
		let total = 0;
		let flag = false;
		for (let i = 2; i < keys.length; i += 1) {
			if (keys[i] === "") flag = true;
			total += Number(keys[i]);
		}

		if (Number.isNaN(total) || (flag === true && total === 0)) {
			return "-1";
		}
		return total.toFixed(2);
	}

	const parameterRows = useMemo(
		() => [
			{
				Label: (
					<Box>
						<Typography variant="body2" display="inline" mr="0.675rem">
							{MESSAGE_STRINGS["GPFC.label.row.UoM"]}
						</Typography>
					</Box>
				),
				Value: (
					<StyledRowBox>
						<GeneralDropdown
							placeholderText={MESSAGE_STRINGS["GPFC.dropdown.placeholder"]}
							value={
								uom.UoM !== "UoM"
									? `${uom.UoM}`
									: DROPDOWN_CONSTANTS.DEFAULT_VALUE
							}
							onChange={(e) => {
								handleChangeUoM(e);
							}}
							data-testid={PLANT_FINANCIAL_ID.UOM_DROPDOWN}
							sx={{ width: "9.5rem" }}
							error={uom.error}
						>
							<MenuItem
								value={PFC_VALUES.UNIT}
								key={1}
								data-testid={PLANT_FINANCIAL_ID.UOM_ITEMS}
							>
								{MESSAGE_STRINGS["GPFC.label.UNIT"]}
							</MenuItem>
							<MenuItem
								value={PFC_VALUES.POUND}
								key={2}
								data-testid={PLANT_FINANCIAL_ID.UOM_ITEMS}
							>
								{MESSAGE_STRINGS["GPFC.label.POUND"]}
							</MenuItem>
							<MenuItem
								value={PFC_VALUES.DOLLAR}
								key={3}
								data-testid={PLANT_FINANCIAL_ID.UOM_ITEMS}
							>
								{MESSAGE_STRINGS["GPFC.label.DOLLAR"]}
							</MenuItem>
						</GeneralDropdown>
					</StyledRowBox>
				),
			},
			{
				Label: (
					<Box>
						<Typography
							variant="body2"
							display="inline"
							mr="0.675rem"
							sx={{
								verticalAlign: "bottom",
							}}
						>
							{MESSAGE_STRINGS["GPFC.heading.contributionMargin"]}
						</Typography>
						<StyledTooltip
							title={
								<Typography
									variant="subtitle"
									data-testid={`${PLANT_FINANCIAL_ID.TOOLTIP_CONTRIBUTIONMARGIN}-content`}
								>
									{MESSAGE_STRINGS["GPFC.tooltip.contributionMargin"]}
								</Typography>
							}
							data-testid={`${PLANT_FINANCIAL_ID.TOOLTIP_CONTRIBUTIONMARGIN}-icon`}
						>
							<span>
								<InfoIcon />
							</span>
						</StyledTooltip>
					</Box>
				),
				Value: (
					<StyledRowBox
						sx={{
							width: "100%",
						}}
					>
						<Box sx={{ display: "flex", alignItems: "baseline" }}>
							<Typography variant="body2" mr=".675rem">
								$
							</Typography>
							<PFCTextfield
								fieldName={MESSAGE_STRINGS["GPFC.heading.contributionMargin"]}
								formik={formik}
								generalConfigDispatch={generalConfigDispatch}
								testid={PLANT_FINANCIAL_ID.TEXTFIELD_CONTRIBUTIONMARGIN}
							/>
							<Typography variant="body2" display="inline" ml="1.2rem">
								{`${MESSAGE_STRINGS["GPFC.accessor.contribution.label"]} ${uom.UoM} ${MESSAGE_STRINGS["GPFC.headers.uom.part2"]}`}
							</Typography>
						</Box>
					</StyledRowBox>
				),
			},
			{
				Label: (
					<Box>
						<Typography
							variant="body2"
							display="inline"
							mr="0.675rem"
							sx={{
								verticalAlign: "bottom",
							}}
						>
							{MESSAGE_STRINGS["GPFC.heading.isPlantSoldout"]}
						</Typography>
						<StyledTooltip
							title={
								<Typography
									variant="subtitle"
									data-testid={`${PLANT_FINANCIAL_ID.TOOLTIP_ISPLANTSOLDOUT}-content`}
								>
									{MESSAGE_STRINGS["GPFC.tooltip.isPlantSoldout"]}
								</Typography>
							}
							data-testid={`${PLANT_FINANCIAL_ID.TOOLTIP_ISPLANTSOLDOUT}-icon`}
						>
							<span>
								<InfoIcon />
							</span>
						</StyledTooltip>
					</Box>
				),
				Value: (
					<StyledRowBox>
						<GeneralDropdown
							placeholderText="Select"
							value={
								sold.sold !== "" ? sold.sold : DROPDOWN_CONSTANTS.DEFAULT_VALUE
							}
							sx={{ width: "9.5rem" }}
							onChange={(e) => {
								updateStepStatusInProgress(
									generalConfigDispatch,
									GENERAL_CONFIGURATION_STEPS.PLANT_FINANCIAL_CALCULATIONS
								);
								handleChangeSold(e);
							}}
							error={sold.error}
							data-testid={PLANT_FINANCIAL_ID.SOLD_OUT_DROPDOWN}
						>
							<MenuItem
								value="true"
								data-testid={PLANT_FINANCIAL_ID.SOLD_OUT_ITEMS}
							>
								{MESSAGE_STRINGS["GPFC.plantSoldout.options.true"]}
							</MenuItem>
							<MenuItem
								value="false"
								data-testid={PLANT_FINANCIAL_ID.SOLD_OUT_ITEMS}
							>
								{MESSAGE_STRINGS["GPFC.plantSoldout.options.false"]}
							</MenuItem>
						</GeneralDropdown>
					</StyledRowBox>
				),
			},
			{
				Label: (
					<Box>
						<Typography
							variant="body2"
							display="inline"
							mr="0.675rem"
							sx={{
								verticalAlign: "bottom",
							}}
						>
							{MESSAGE_STRINGS["GPFC.heading.scrapPercentage"]}
						</Typography>
						<StyledTooltip
							title={
								<Typography
									variant="subtitle"
									data-testid={`${PLANT_FINANCIAL_ID.TOOLTIP_SCRAPPERCENTAGE}-content`}
								>
									{MESSAGE_STRINGS["GPFC.tooltip.scrapPercentage"]}
								</Typography>
							}
							data-testid={`${PLANT_FINANCIAL_ID.TOOLTIP_SCRAPPERCENTAGE}-icon`}
						>
							<span>
								<InfoIcon />
							</span>
						</StyledTooltip>
					</Box>
				),
				Value: (
					<StyledRowBox
						sx={{
							width: "100%",
						}}
					>
						<Box>
							<PFCTextfield
								fieldName={MESSAGE_STRINGS["GPFC.heading.scrapPercentage"]}
								formik={formik}
								generalConfigDispatch={generalConfigDispatch}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">%</InputAdornment>
									),
								}}
								regexValidator={SCRAP_PERCENTAGE_REGEX}
								maxLength={6}
								testid={PLANT_FINANCIAL_ID.TEXTFIELD_AVERAGESCRAP}
							/>
						</Box>
					</StyledRowBox>
				),
			},
		],
		[
			uom,
			sold,
			formik,
			handleChangeSold,
			handleChangeUoM,
			generalConfigDispatch,
		]
	);

	const variableColoumns = useMemo(() => {
		return [
			{
				Header: (
					<Typography
						data-testid={`${PLANT_FINANCIAL_ID.VARIABLE_HEADER_LABEL}`}
						variant="body2"
					>
						{MESSAGE_STRINGS["GPFC.heading.variableCosts"]}
					</Typography>
				),
				accessor: MESSAGE_STRINGS["GPFC.accessor.label"],
				width: "49%",
			},
			{
				Header: (
					<Typography
						variant="body2"
						sx={{ display: "flex", justifyContent: "flex-end" }}
						data-testid={`${PLANT_FINANCIAL_ID.VARIABLE_HEADER_VALUE}`}
					>
						{`${MESSAGE_STRINGS["GPFC.headers.uom.part1"]} ${uom.UoM} ${MESSAGE_STRINGS["GPFC.headers.uom.part2"]}`}
					</Typography>
				),
				accessor: MESSAGE_STRINGS["GPFC.accessor.value"],
			},
		];
	}, [uom]);

	const variableRows = useMemo(
		() => [
			{
				Label: (
					<Box>
						<Typography variant="body2" display="inline" mr="0.675rem">
							{MESSAGE_STRINGS["GPFC.label.hourlyAssociates"]}
						</Typography>
					</Box>
				),
				Value: (
					<StyledRowBox
						sx={{
							width: "100%",
						}}
					>
						<Box sx={{ display: "flex", alignItems: "baseline" }}>
							<Typography variant="body2" mr=".675rem">
								$
							</Typography>
							<PFCTextfield
								fieldName={MESSAGE_STRINGS["GPFC.label.hourlyAssociates"]}
								formik={formik}
								generalConfigDispatch={generalConfigDispatch}
								testid={PLANT_FINANCIAL_ID.TEXTFIELD_HOURLYASSOCIATES}
							/>
						</Box>
					</StyledRowBox>
				),
			},
			{
				Label: (
					<Box>
						<Typography variant="body2" display="inline" mr="0.675rem">
							{MESSAGE_STRINGS["GPFC.label.salariedAssociates"]}
						</Typography>
					</Box>
				),
				Value: (
					<StyledRowBox
						sx={{
							width: "100%",
						}}
					>
						<Box sx={{ display: "flex", alignItems: "baseline" }}>
							<Typography variant="body2" mr=".675rem">
								$
							</Typography>
							<PFCTextfield
								fieldName={MESSAGE_STRINGS["GPFC.label.salariedAssociates"]}
								formik={formik}
								generalConfigDispatch={generalConfigDispatch}
								testid={PLANT_FINANCIAL_ID.TEXTFIELD_SALARIEDASSOCIATES}
							/>
						</Box>
					</StyledRowBox>
				),
			},
			{
				Label: (
					<Box>
						<Typography variant="body2" display="inline" mr="0.675rem">
							{MESSAGE_STRINGS["GPFC.label.materialCost"]}
						</Typography>
					</Box>
				),
				Value: (
					<StyledRowBox
						sx={{
							width: "100%",
						}}
					>
						<Box sx={{ display: "flex", alignItems: "baseline" }}>
							<Typography variant="body2" mr=".675rem">
								$
							</Typography>
							<PFCTextfield
								fieldName={MESSAGE_STRINGS["GPFC.label.materialCost"]}
								formik={formik}
								generalConfigDispatch={generalConfigDispatch}
								testid={PLANT_FINANCIAL_ID.TEXTFIELD_MATERIALCOST}
							/>
						</Box>
					</StyledRowBox>
				),
			},
			{
				Label: (
					<Box>
						<Typography variant="body2" display="inline" mr="0.675rem">
							{MESSAGE_STRINGS["GPFC.label.utilityCost"]}
						</Typography>
					</Box>
				),
				Value: (
					<StyledRowBox
						sx={{
							width: "100%",
						}}
					>
						<Box sx={{ display: "flex", alignItems: "baseline" }}>
							<Typography variant="body2" mr=".675rem">
								$
							</Typography>
							<PFCTextfield
								fieldName={MESSAGE_STRINGS["GPFC.label.utilityCost"]}
								formik={formik}
								generalConfigDispatch={generalConfigDispatch}
								testid={PLANT_FINANCIAL_ID.TEXTFIELD_UTILITYCOST}
							/>
						</Box>
					</StyledRowBox>
				),
			},
			{
				Label: (
					<Box>
						<Typography variant="body2" display="inline" mr="0.675rem">
							{MESSAGE_STRINGS["GPFC.label.miscellaneousVariableCost"]}
						</Typography>
					</Box>
				),
				Value: (
					<StyledRowBox
						sx={{
							width: "100%",
						}}
					>
						<Box sx={{ display: "flex", alignItems: "baseline" }}>
							<Typography variant="body2" mr=".675rem">
								$
							</Typography>
							<PFCTextfield
								textAlign="right"
								fieldName={
									MESSAGE_STRINGS["GPFC.label.miscellaneousVariableCost"]
								}
								generalConfigDispatch={generalConfigDispatch}
								formik={formik}
								testid={PLANT_FINANCIAL_ID.TEXTFIELD_MISCELLANEOUSCOST}
							/>
						</Box>
					</StyledRowBox>
				),
			},
		],
		[formik, generalConfigDispatch]
	);

	function handlePreviousClick() {
		onPreviousClickAPI();
	}

	async function handleSubmit(_e, isEditModal = false) {
		if (!isEmpty(formik.errors) || uom.UoM === "UoM" || sold.sold === "") {
			if (uom.UoM === "UoM") {
				setUom({ ...uom, error: true });
			}
			if (sold.sold === "") {
				setSold({ ...sold, error: true });
			}
			if (!isEmpty(formik.errors)) {
				const erroneousValues = {};
				const errorKeys = Object.keys(formik.errors);
				errorKeys.forEach((key) => {
					erroneousValues[key] = true;
				});
				formik.setTouched({
					...erroneousValues,
				});
			}
			toastDispatch({
				type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
				payload: {
					message: MESSAGE_STRINGS["GPFC.errors.submit.errorsPresent"],
				},
			});
		} else {
			//	converting data in the required format before making the put call
			const {
				[MESSAGE_STRINGS["GPFC.heading.scrapPercentage"]]: scrapPercentage,
				[MESSAGE_STRINGS["GPFC.heading.contributionMargin"]]:
					contributionMargin,
			} = formik.values;
			const payload = {};
			payload.plantId = entityId;
			const parameters = {
				UoM: `${uom.UoM.toUpperCase()}S`,
				soldOut: sold.sold === "true",
				scrapPercentage: Number(scrapPercentage),
				contributionMargin: Number(contributionMargin),
			};
			const data = [];
			const variableValues = Object.entries(formik.values);
			for (let i = 2; i < variableValues.length; i += 1) {
				const entry = {};
				const [label, value] = variableValues[i];
				entry.label = label;
				entry.value = Number(value);
				data.push(entry);
			}
			data.push({
				label: "Variable Cost",
				value: Number(getTotalVariableCost()),
			});
			payload.parameters = parameters;
			payload.data = data;
			const payloadJSON = JSON.stringify(payload);
			await setPFCdata(payloadJSON);
			if (isEditModal) {
				handleNavigation(factoryGeneralAction?.location);
			}
			setUom({ ...uom, initialConfig: false });
		}
	}
	const isSameLocation =
		factoryGeneralAction.location === APP_URL.CONFIG ||
		factoryGeneralAction.location === APP_URL.GENERAL_CONFIG;
	useEffect(() => {
		if (
			steps[GENERAL_CONFIGURATION_STEPS.PLANT_FINANCIAL_CALCULATIONS - 1]
				.stepStatus === STEPPER_STATUSES.IN_PROGRESS &&
			isMaintenanceMode === true
		) {
			updateFactoryGeneralEditAction({}, { isUnsavedChange: true });
		} else {
			updateFactoryGeneralEditAction({}, { isUnsavedChange: false });
		}
		return () => {
			updateFactoryGeneralEditAction(
				{ isUserNavigating: false, location: "", type: null },
				{ isUnsavedChange: false }
			);
		};
	}, [steps, isMaintenanceMode]);
	return (
		<>
			<GeneralConfigBody tabTitle={MESSAGE_STRINGS["GPFC.pageHeading.heading"]}>
				<CommonBackdrop
					open={
						isGetFinancialLoading || isGetFinancialFetching || isSubmitLoading
					}
				>
					<LoadingIndicator />
				</CommonBackdrop>
				{!isGetFinancialLoading && !isGetFinancialFetching && (
					<>
						<StyledTableBox
							data-testid={PLANT_FINANCIAL_ID.CONTRIBUTION_MARGIN}
						>
							<GeneralTable data={parameterRows} columns={parameterColoumns} />
						</StyledTableBox>
						<StyledTableBox data-testid={PLANT_FINANCIAL_ID.VARIABLE_COST}>
							<GeneralTable data={variableRows} columns={variableColoumns} />
						</StyledTableBox>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							backgroundColor="#23262980"
							sx={{
								lineHeight: 1,
								minHeight: "3em",
							}}
						>
							<Box sx={{ padding: "0 1.5rem" }}>
								<Typography
									variant="body2"
									display="inline"
									mr="0.675rem"
									fontWeight="bold"
								>
									Total Variable Cost
								</Typography>
							</Box>
							<Box
								sx={{
									display: "flex",
									alignItems: "baseline",
									padding: "0 .6rem",
								}}
							>
								<Typography
									variant="body2"
									mr=".675rem"
									textAlign="right"
									sx={{ fontWeight: "bold" }}
									data-testid={PLANT_FINANCIAL_ID.TOTAL_COST}
									display={getTotalVariableCost() === "-1" ? "none" : "block"}
								>
									{`$${getTotalVariableCost()}`}
								</Typography>
							</Box>
						</Box>
					</>
				)}
			</GeneralConfigBody>
			{!isGetFinancialLoading && !isGetFinancialFetching && (
				<GeneralConfigFooter
					nextButtonText={
						isMaintenanceMode ? MESSAGE_STRINGS["FGC.save"] : "Submit"
					}
					onNextClick={handleSubmit}
					onPreviousClick={handlePreviousClick}
					isSaveEnabled={!isEmpty(formik.errors)}
				/>
			)}
			<NavigationModal
				open={
					Boolean(factoryGeneralAction?.isUserNavigating) &&
					!isSameLocation &&
					!isInterDepedencyState
				}
				onSave={async (e) => {
					updateFactoryGeneralEditAction({
						isUserNavigating: false,
						type: null,
					});
					handleSubmit(e, true);
				}}
				onClose={() => handleNavigation(factoryGeneralAction?.location)}
			/>
		</>
	);
}
PlantFinancialCalculations.propTypes = {
	onPreviousClickAPI: PropTypes.func,
	onNextClickAPI: PropTypes.func,
	navigateTo: PropTypes.func,
	isInterDepedencyState: PropTypes.bool,
};

export default PlantFinancialCalculations;
