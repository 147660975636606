import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { globalstate$ } from "@smf/ui-util-app";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import ConfigSteps from "../components/ConfigSteps";
import Schedule from "./Schedule";
import Crews from "./Crews";
import Rotations from "./Rotations";
import WeeklyAndDailyRotations from "./WeeklyAndDailyRotations";
import EquipmentIntelligence from "./EquipmentIntelligence";
import PlantFinancialCalculations from "./PlantFinancialCalculations";
import {
	APP_URL,
	BUTTON_TYPE,
	CONFIG_CONTEXT,
	GENERAL_CONFIGURATION_STEPS,
	NAVIAGTION_TYPE,
	QUERY_CONSTANTS,
	STEPPER_STATUSES,
	TOAST_REDUCER_CONSTANTS,
} from "../constants";
import { ConfirmModalProvider } from "../context/generalModalContext";
import { useToastContext } from "../context/toastContext";
import { useGeneralConfigContext } from "../context/generalConfigContext";
import {
	discardFactoryGeneral,
	getGeneralConfigStatus,
	updateGeneralConfigStep,
} from "../utils/apiHelper";
import LoadingIndicator from "../components/LoadingIndicator";
import CommonBackdrop from "../components/CommonBackdrop";
import MESSAGE_STRINGS from "../constants/en-us";
import {
	updateFactoryGeneralEditAction,
	updateGeneralConfigStatus,
} from "../utils/rxjs";
import "./GeneralConfig.css";
import {
	updateModalStateAfterStatus,
	useInterDependecyContext,
} from "../context/interDependecyModalContext";
import useRxjsState from "../hooks/useRxjsState";
import NavigationModal from "./layouts/NavigationModal/NavigationModal";
import { getMessageForNavigationModal } from "../utils/helpers";

function GeneralConfig() {
	const {
		generalConfigState: {
			steps,
			currentStep,
			isMaintenanceMode,
			isGeneralConfigCompleted,
			isFirstAPICall,
		},
		generalConfigDispatch,
	} = useGeneralConfigContext();
	const { interDependecyDispatch, interDependecyState } =
		useInterDependecyContext();
	const { toastDispatch } = useToastContext();
	const { rxjsState } = useRxjsState();

	const navigate = useNavigate();
	const plantId = globalstate$?.value?.plantId;
	const isInterDepedencyState =
		interDependecyState.shift.status === "ACTIVE" ||
		interDependecyState.crew.status === "ACTIVE" ||
		interDependecyState.rotation.status === "ACTIVE";

	const {
		data: newStepData,
		isLoading: isGetStepStatusLoading,
		isFetching: isGetStepStatusFetching,
		refetch: refetchGeneralStatus,
	} = useQuery(
		[QUERY_CONSTANTS.GET_SILENT_STEP_STATUS],
		() =>
			getGeneralConfigStatus({
				plantId,
				isFirstAPICall: isFirstAPICall || undefined,
			}),
		{
			onSuccess: (response) => {
				generalConfigDispatch({
					type: CONFIG_CONTEXT.STEPPER_CURRENT_STATE_SILENT_UPDATE,
					payload: { ...response },
				});
				if (isMaintenanceMode) {
					updateModalStateAfterStatus(interDependecyDispatch, response?.steps);
				}
			},
			onError: () => {
				toastDispatch({
					type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
					payload: {
						message: MESSAGE_STRINGS["Toast.message.ERROR"],
					},
				});
			},
			refetchOnWindowFocus: false,
			retry: false,
		}
	);

	const {
		data: stepDataPut,
		mutate: updateStepAPI,
		isLoading: isUpdateScheduleLoading,
	} = useMutation((data) => updateGeneralConfigStep(data), {
		onSuccess: (response) => {
			generalConfigDispatch({
				type: CONFIG_CONTEXT.STEPPER_CURRENT_STATE,
				payload: response,
			});
		},
	});

	function updateStep(newStep) {
		if (isMaintenanceMode) {
			const stepData = stepDataPut || newStepData;
			const isCurrentStepInProgress =
				steps[currentStep - 1].stepStatus === STEPPER_STATUSES.IN_PROGRESS;
			generalConfigDispatch({
				type: CONFIG_CONTEXT.SET_CURRENT_STEP,
				payload: {
					updatedStep: newStep,
					stepData,
					currentStep,
					isCurrentStepInProgress,
				},
			});
		} else updateStepAPI({ plantId, newStep });
	}
	/**
	 * This function used to navigate Wherever user clicked
	 * @param {*} location
	 * @returns Nothing
	 */
	function navigateTo(location = "", type = "") {
		if (type === NAVIAGTION_TYPE.STEP) {
			updateStep(location);
			return;
		}

		if (location) {
			navigate(location);
		}
	}
	const { mutate: discardInterdependentAction, isLoading: isDiscardLoading } =
		useMutation(() => discardFactoryGeneral({ factoryID: plantId }), {
			onSuccess: async (res) => {
				if (res.statusResponse) {
					generalConfigDispatch({
						type: CONFIG_CONTEXT.STEPPER_CURRENT_STATE_SILENT_UPDATE,
						payload: { ...res.statusResponse },
					});
				}
				await refetchGeneralStatus();
				navigateTo(rxjsState.factoryGeneralEditState?.action?.location);
			},
		});
	useEffect(() => {
		if (
			steps &&
			steps.some((item) => item.stepStatus === STEPPER_STATUSES.IN_PROGRESS)
		) {
			updateGeneralConfigStatus(false);
		} else if (
			steps &&
			steps
				.filter((item) => item.disabled !== true)
				.every((item) => item.stepStatus === STEPPER_STATUSES.COMPLETED)
		) {
			updateGeneralConfigStatus(true);
		}
	}, [steps]);
	const {
		factoryGeneralEditState: {
			isUnsavedChange = false,
			acion: factoryGeneralAction = {},
		} = {},
	} = rxjsState;
	const isSameLocation =
		factoryGeneralAction.location === APP_URL.CONFIG ||
		factoryGeneralAction.location === APP_URL.GENERAL_CONFIG;

	useEffect(() => {
		if (
			isUnsavedChange === false &&
			isInterDepedencyState &&
			isMaintenanceMode
		) {
			updateFactoryGeneralEditAction({}, { isUnsavedChange: true });
		}
	}, [isInterDepedencyState, isMaintenanceMode, isUnsavedChange]);

	useEffect(() => {
		if (
			!isInterDepedencyState &&
			steps.every((item) => item.stepStatus === STEPPER_STATUSES.COMPLETED)
		) {
			updateFactoryGeneralEditAction({}, { isUnsavedChange: false });
		}
	}, [isInterDepedencyState, steps]);

	useEffect(() => {
		// UnMount
		return function cleanup() {
			updateGeneralConfigStatus(
				isMaintenanceMode && Boolean(isGeneralConfigCompleted),
				{
					isUserNavigating: false,
					location: "",
				}
			); // Revert to old state
		};
	}, [isMaintenanceMode, isGeneralConfigCompleted]);

	return (
		<ConfirmModalProvider>
			<Box
				sx={{
					display: "flex",
					height: "100%",
					flexGrow: 1,
					flexDirection: "column",
					margin: "0 1.5rem",
				}}
			>
				<Box sx={{ marginTop: "1rem" }}>
					<Typography variant="h6">General</Typography>
				</Box>
				<Box
					sx={{
						height: "100%",
						margin: "1rem 0",
						display: "flex",
						padding: "1.5rem",
						flexDirection: "column",
						backgroundColor: "#171717",
					}}
				>
					<ConfigSteps
						currentStep={currentStep}
						steps={steps}
						changeCurrentStep={(step) => {
							if (
								rxjsState.factoryGeneralEditState?.isUnsavedChange &&
								isMaintenanceMode &&
								!isInterDepedencyState
							) {
								updateFactoryGeneralEditAction({
									isUserNavigating: true,
									location: step,
									type: NAVIAGTION_TYPE.STEP,
								});
								return;
							}
							updateStep(step);
						}}
						isInitialSetup={!isMaintenanceMode}
						isNonLinear
					/>
					<Box
						sx={{
							height: "100%",
							display: "flex",
							flexDirection: "column",
							overflowY: "auto",
						}}
					>
						<CommonBackdrop
							open={
								isUpdateScheduleLoading ||
								(isFirstAPICall &&
									(isGetStepStatusFetching || isGetStepStatusLoading))
							}
						>
							<LoadingIndicator />
						</CommonBackdrop>
						{currentStep === GENERAL_CONFIGURATION_STEPS.SCHEDULE && (
							<Schedule
								onNextClickAPI={() =>
									updateStep(GENERAL_CONFIGURATION_STEPS.CREWS)
								}
								onPreviousClickAPI={() =>
									updateStep(GENERAL_CONFIGURATION_STEPS.EQUIPMENT_INTELLIGENCE)
								}
								navigateTo={navigateTo}
								isInterDepedencyState={isInterDepedencyState}
								refetchGeneralStatus={refetchGeneralStatus}
							/>
						)}
						{currentStep === GENERAL_CONFIGURATION_STEPS.CREWS && (
							<Crews
								onNextClickAPI={() =>
									updateStep(GENERAL_CONFIGURATION_STEPS.ROTATION)
								}
								onPreviousClickAPI={() =>
									updateStep(GENERAL_CONFIGURATION_STEPS.SCHEDULE)
								}
								navigateTo={navigateTo}
								isInterDepedencyState={isInterDepedencyState}
								refetchGeneralStatus={refetchGeneralStatus}
							/>
						)}
						{currentStep === GENERAL_CONFIGURATION_STEPS.ROTATION && (
							<Rotations
								onNextClickAPI={() =>
									updateStep(GENERAL_CONFIGURATION_STEPS.WEEKLY_ROTATION)
								}
								onPreviousClickAPI={() =>
									updateStep(GENERAL_CONFIGURATION_STEPS.CREWS)
								}
								navigateTo={navigateTo}
								isInterDepedencyState={isInterDepedencyState}
								refetchGeneralStatus={refetchGeneralStatus}
							/>
						)}
						{currentStep === GENERAL_CONFIGURATION_STEPS.WEEKLY_ROTATION && (
							<WeeklyAndDailyRotations
								onNextClickAPI={() =>
									updateStep(
										GENERAL_CONFIGURATION_STEPS.PLANT_FINANCIAL_CALCULATIONS
									)
								}
								onPreviousClickAPI={() =>
									updateStep(GENERAL_CONFIGURATION_STEPS.ROTATION)
								}
								refetchGeneralStatus={refetchGeneralStatus}
							/>
						)}
						{currentStep ===
							GENERAL_CONFIGURATION_STEPS.EQUIPMENT_INTELLIGENCE && (
							<EquipmentIntelligence
								onNextClickAPI={() =>
									updateStep(GENERAL_CONFIGURATION_STEPS.SCHEDULE)
								}
								refetchGeneralStatus={refetchGeneralStatus}
							/>
						)}
						{currentStep ===
							GENERAL_CONFIGURATION_STEPS.PLANT_FINANCIAL_CALCULATIONS && (
							<PlantFinancialCalculations
								onPreviousClickAPI={() =>
									updateStep(GENERAL_CONFIGURATION_STEPS.WEEKLY_ROTATION)
								}
								onNextClickAPI={() =>
									updateStep(GENERAL_CONFIGURATION_STEPS.EQUIPMENT_INTELLIGENCE)
								}
								navigateTo={navigateTo}
								isInterDepedencyState={isInterDepedencyState}
							/>
						)}
					</Box>
				</Box>
			</Box>
			<NavigationModal
				open={
					Boolean(
						rxjsState.factoryGeneralEditState?.action?.isUserNavigating
					) &&
					!isSameLocation &&
					isInterDepedencyState
				}
				onSave={async () => {
					updateFactoryGeneralEditAction({
						isUserNavigating: false,
						type: null,
					});
				}}
				isLoading={isDiscardLoading}
				onClose={discardInterdependentAction}
				customTitle={MESSAGE_STRINGS["InterDependencyModal.title"]}
				customText={getMessageForNavigationModal(interDependecyState)}
				noteText={MESSAGE_STRINGS["InterDependencyModal.confirmText"]}
				cancelText={MESSAGE_STRINGS["InterDependencyModal.button.discard"]}
				confirmText={
					MESSAGE_STRINGS["InterDependencyModal.button.continueEditing"]
				}
				buttonTypes={{
					left: BUTTON_TYPE.SECONDARY,
					right: BUTTON_TYPE.SECONDARY,
				}}
			/>
		</ConfirmModalProvider>
	);
}

export default GeneralConfig;
