import React from "react";
import { globalstate$ } from "@smf/ui-util-app";
import PropTypes from "prop-types";
import GeneralConfigBody from "../layouts/GeneralConfigBody";
import GeneralConfigFooter from "../layouts/GeneralConfigFooter";
import WeeklyAndDailyCalendar from "../../components/WeeklyAndDailyCalendar";
import CalendarContainer from "./CalendarContainer";
import UploadFile from "../../components/UploadFile";
import MESSAGE_STRINGS from "../../constants/en-us";
import { useGeneralConfigContext } from "../../context/generalConfigContext";
import { useWeeklyDailyRotations } from "./useWeeklyDailyRotations";
import {
	TOAST_REDUCER_CONSTANTS,
	WEEKLY_DAILY_REDUCER_CONSTANTS,
} from "../../constants";
import LoadingIndicator from "../../components/LoadingIndicator";
import CommonBackdrop from "../../components/CommonBackdrop";
import { useToastContext } from "../../context/toastContext";

function WeeklyAndDailyRotations({
	onNextClickAPI,
	onPreviousClickAPI,
	refetchGeneralStatus,
}) {
	const { toastDispatch } = useToastContext();
	const plantId = globalstate$?.value?.plantId;
	const {
		state,
		dispatch,
		onExport,
		showBackdropLoader,
		uploadWeeklyRotationsAPI,
		isUploadLoading,
		isUploadError,
		onDateChanges,
		isWeeklyRotation,
		handleViewChange,
		onEventSave,
		isDuplicateCrewAssigned,
	} = useWeeklyDailyRotations(plantId, refetchGeneralStatus);

	const {
		showInitialStep,
		isPreviewComponentEnabled,
		fileObject,
		errorMessages,
		viewData,
		rotations,
		isFileUploadedOnce,
		crews,
		doesNewRotationExist,
		areRotationsPresentForNextDay,
		areRotationsPresentForNextWeek,
		requireNewRotationAssignment,
	} = state;
	const {
		generalConfigState: { isMaintenanceMode },
	} = useGeneralConfigContext();

	function handleNextClick() {
		if (isFileUploadedOnce) {
			onNextClickAPI();
		} else {
			toastDispatch({
				type: TOAST_REDUCER_CONSTANTS.SHOW_WARNING_TOAST,
				payload: {
					message: MESSAGE_STRINGS["WeeklyAndDailyRotation.onNextClick.error"],
				},
			});
		}
	}
	function handlePreviousClick() {
		onPreviousClickAPI();
	}
	return (
		<>
			<GeneralConfigBody>
				<CalendarContainer
					doesNewRotationExist={doesNewRotationExist}
					areRotationsPresentForNextDay={areRotationsPresentForNextDay}
					areRotationsPresentForNextWeek={areRotationsPresentForNextWeek}
					requireNewRotationAssignment={requireNewRotationAssignment}
				>
					<UploadFile
						getUploadUrl={uploadWeeklyRotationsAPI}
						isPreviewComponentEnabled={isPreviewComponentEnabled}
						tabTitle={MESSAGE_STRINGS["WeeklAndDailyRotations.tabTitle"]}
						exportCsvTitle={
							MESSAGE_STRINGS["WeeklyAndDailyRotation.button.exportData"]
						}
						showInitialStep={showInitialStep}
						isLoading={isUploadLoading}
						isFileUploadError={isUploadError}
						fileObject={fileObject}
						setFileObject={(file) => {
							dispatch({
								type: WEEKLY_DAILY_REDUCER_CONSTANTS.SET_FILE_OBJECT,
								payload: { file },
							});
						}}
						errorMessages={errorMessages}
						uploadTitle={
							MESSAGE_STRINGS[
								"WeeklAndDailyRotations.RotationsCalendar.upload.title"
							]
						}
						uploadTooltipContent={
							MESSAGE_STRINGS[
								"WeeklyAndDailyRotations.RotationsCalendar.upload.info"
							]
						}
						onExport={onExport}
						isTopUploadVisible={isWeeklyRotation}
					>
						<WeeklyAndDailyCalendar
							viewData={viewData}
							onDateChanges={onDateChanges}
							isWeeklyRotation={isWeeklyRotation}
							handleViewChange={handleViewChange}
							rotations={rotations}
							crews={crews}
							onEventSave={onEventSave}
							isDuplicateCrewAssigned={isDuplicateCrewAssigned}
						/>
					</UploadFile>
				</CalendarContainer>
				<CommonBackdrop open={showBackdropLoader}>
					<LoadingIndicator />
				</CommonBackdrop>
			</GeneralConfigBody>

			<GeneralConfigFooter
				onNextClick={handleNextClick}
				onPreviousClick={handlePreviousClick}
				showSave={isMaintenanceMode}
			/>
		</>
	);
}

WeeklyAndDailyRotations.propTypes = {
	onNextClickAPI: PropTypes.func,
	onPreviousClickAPI: PropTypes.func,
	refetchGeneralStatus: PropTypes.func,
};

export default WeeklyAndDailyRotations;
